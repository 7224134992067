<template>
  <b-container id="room" fluid="fluid">
    <b-row id="blackboard" :class="blackboard_color">
      <b-col>
        <div class="video_wrapper">
          <div id="waiting_box" v-show="!isRemoteConnected">
            <div v-if="type">
              <div v-if="this.class_status">
                <h3>La classe e' pronta</h3>
                <button v-on:click="play_room" id="start_class" >Entra</button>
              </div>
              <div v-else>
                <h3>La classe non e' pronta</h3>
                <button v-on:click="checkClass" id="check_class" >Verifica</button>
              </div>
            </div>
          </div>
          <video id="remoteVideo" ref="remoteVideo" autoplay v-show="isRemoteConnected" :class="pos_video"></video>
        </div>
      </b-col>
    </b-row>
    <div v-show="ctrl_local_camera" id="local_video">
      <video id="local_vid" ref="local_vid" autoplay muted style="max-height: 400px; max-width: 100%;"></video>
      <!--<b-form-input v-model="local_volume" id="local_volume" ref="local_volume" type="text" ></b-form-input>
      <button v-on:click="change_volume" id="bttn_changevol" class="btn btn-lg btn-outline-secondary"></button>-->
    </div>

    <nav class="navbar fixed-bottom navbar-light control_bar">
      <div class="navbar-brand">
        <span id="username">{{ username }}</span>
        <div id="ctrl_video">
          <button v-on:click="mute_mic" id="bttn_mute" class="btn btn-lg btn-outline-secondary rounded-circle mx-3">
            <span v-if="audioMuted" class="material-icons pt-2">mic_off</span>
            <span v-else class="material-icons pt-2">mic</span>
          </button>
          <button v-on:click="mute_video" id="bttn_vid_mute" class="btn btn-lg btn-outline-secondary rounded-circle mx-3">
            <span v-if="videoMuted" class="material-icons pt-2">videocam_off</span>
            <span v-else class="material-icons pt-2">videocam</span>
          </button>
        </div>
        <button id="esci" v-on:click="logout" title="esci">Esci</button>
      </div>
    </nav>
  </b-container>
</template>

<script>
import {BASE_URL, socket} from "@/main";

export default {
  name: "RemoteCamera",

  props: {
    room_id: String,
    username: String,
    type: Number
  },
  created() {
    window.addEventListener('beforeunload', this.destroyComponent)
  },
  mounted() {
    this.checkClass();
    //this.checkAgent();
    this.start_camera();
  },
  data() {
    return {
      //local_volume: 0,
      audioMuted: false,
      videoMuted: false,
      mediaConstraints: {
        audio: true,
        video: {
          width: 1024,
          height: 768
        }
      },
      volume: 80,
      play_with_remote: this.type == 0, //0==remote, 1=local
      ctrl_local_camera: true,
      ctrl_video_settings: true,
      ctrl_remote_setting: false,
      isLocalConnected: false,
      isRemoteConnected: false,
      localPeer: {},
      peer_list: {},
      video_list: {},
      agent: {'status': 'off'},
      partecipants: {},
      class_status: false,
      pc_config: {
        iceServers: [
          {
            urls: [
              'stun:stun.l.google.com:19302',
              'stun:stun1.l.google.com:19302',
              'stun:stun2.l.google.com:19302',
              'stun:stun3.l.google.com:19302',
              'stun:stun4.l.google.com:19302'
            ]
          },
        ]
      }
    }
  },
  computed: {
    roomStarted: function(){
      return (this.type == 0 || this.play_with_remote) && this.isLocalConnected
    },
    agent_status: function(){
      return this.agent['status'] == 'off' ? 'red' : 'green'
    },
    pos_video: function(){
      return this.type == 0 ? 'd_vertical' : 'd_horizontal'
    },
    blackboard_color: function(){
      return this.isRemoteConnected ? 'blackboard_color_black' : 'blackboard_color_green'
    }
  },
  watch: {
    partecipants: function(){
      this.class_status = (this.partecipants != undefined && Object.keys(this.partecipants).length>0)
    },
    isLocalConnected: function() {
      if (this.roomStarted) {
        socket.connect()
      }
    },
    play_with_remote: function() {
      if (this.roomStarted) {
        console.log("play_with_remote connect")
        socket.connect()
      }
    },
    volume: function() {
      this.$http.post(BASE_URL+'/api/agent/volume', {
        "volume": ''+this.volume
      }, {
        headers: {
          'Content-type': 'application/json',
          'authorization': 'Bearer '+localStorage.getItem("jwt")
        }
      })
      .then((response) => {
        console.log("["+response+"] change volume to: "+this.volume)
      })
      .catch((error) => {
        this.error401 = true;
        console.log(error)
      })
    }
  },
  methods: {
    /*change_volume: function () {
      this.$refs["remoteVideo"].volume=this.local_volume/100
    },*/
    start_camera: function () {
      navigator.mediaDevices.getUserMedia(this.mediaConstraints)
          .then((stream)=>{
            console.log("get local_video")
            this.$refs["local_vid"].srcObject = stream
            this.isLocalConnected=true
            this.camera_allowed=true
          })
          .catch((e)=>{
            console.log("Error! Unable to start video! ", e);
            console.log(e)
            console.log(e.stack)
          });
    },
    checkClass: function(){
      this.$http.get(BASE_URL+'/api/user/room?id='+this.room_id, {
        headers: {
          'Content-type': 'application/json',
          'authorization': 'Bearer '+localStorage.getItem("jwt")
        }
      })
      .then((response) => {
        this.partecipants = response.data
      })
      .catch((error) => {
        this.error401 = true;
        console.log(error)
      })
    },
    checkAgent: function(){
      this.$http.get(BASE_URL+'/api/agent/status', {
        headers: {
          'Content-type': 'application/json',
          'authorization': 'Bearer '+localStorage.getItem("jwt")
        }
      })
      .then((response) => {
        console.log("my agent status: "+response["status"])
        this.agent["status"] = response["status"]
      })
      .catch((error) => {
        this.error401 = true;
        console.log(error)
      })
    },
    logout_account: function(){
      this.$http.get(BASE_URL+'/api/logout', {
        headers: {
          'Content-type': 'application/json',
          'authorization': 'Bearer '+localStorage.getItem("jwt")
        }
      })
      .then((response) => {
        console.log("logout user: "+response["status"])
      })
      .catch((error) => {
        this.error401 = true;
        console.log(error)
      })
    },
    resetRoom: function() {
      console.log("resetRoom")
      this.isRemoteConnected=false;
      this.localPeer={};
      this.peer_list={};
      this.video_list={};
    },
    logout: function() {
      this.destroyComponent()
      //this.logout_account()
      this.$refs["local_vid"].srcObject.getTracks().forEach(mt => {
        mt.stop()
        localStorage.removeItem('jwt')
        this.$router.push('/login')
      })
    },
    mute_mic: function () {
      this.audioMuted = !this.audioMuted;
      let local_stream = this.$refs["local_vid"].srcObject;
      local_stream.getAudioTracks().forEach((track)=>{track.enabled = !this.audioMuted;});
    },
    mute_video: function () {
      this.videoMuted = !this.videoMuted;
      let local_stream = this.$refs["local_vid"].srcObject;
      local_stream.getVideoTracks().forEach((track)=>{track.enabled = !this.videoMuted;});
    },
    invite: function (peer_id) {
      if(this.peer_list[peer_id]){
        console.log("Attempting to start a connection that already exists on "+ peer_id)
      } else if(peer_id === this.localPeer.id) {
        console.log("Trying to connect to self");
      } else {
        console.log('Creating peer connection for '+peer_id+'...');
        this.createPeerConnection(peer_id);

        let local_stream = this.$refs["local_vid"].srcObject;
        if(local_stream == null) console.log("Local stream has not been configured")
        local_stream.getTracks().forEach((track) => {
          this.peer_list[peer_id].addTrack(track, local_stream);
        });
      }
    },
    createPeerConnection: function (peer_id) {
      console.log("createPeerConnection----------------")
      this.peer_list[peer_id] = new RTCPeerConnection(this.pc_config);

      this.peer_list[peer_id].onicecandidate = (event) => {this.handleICECandidateEvent(event, peer_id)};
      this.peer_list[peer_id].ontrack = (event) => {this.handleTrackEvent(event, peer_id)};
      this.peer_list[peer_id].onnegotiationneeded = () => {this.handleNegotiationNeededEvent(peer_id)};
      this.peer_list[peer_id].onclose = () => {}
    },
    handleICECandidateEvent: function (event, peer_id) {
      if (event.candidate) {
        this.sendViaServer({
          "sender_id": this.localPeer.id,
          "target_id": peer_id,
          "type": "new-ice-candidate",
          "candidate": event.candidate
        });
      }
    },
    handleNegotiationNeededEvent: function (peer_id) {
      console.log("handleNegotiationNeededEvent----------------")
      this.peer_list[peer_id].createOffer()
          .then((offer)=>{return this.peer_list[peer_id].setLocalDescription(offer);})
          .then(()=>{
            console.log('sending offer to '+peer_id+' ...');
            this.isRemoteConnected = true
            this.sendViaServer({
              "sender_id": this.localPeer.id,
              "target_id": peer_id,
              "type": "offer",
              "sdp": this.peer_list[peer_id].localDescription
            });
          })
          .catch(this.log_error);
    },
    handleTrackEvent: function (event, peer_id) {
      console.log('track event recieved from '+peer_id);
      if(event.streams) {
        this.$refs["remoteVideo"].srcObject = event.streams[0];
      }
    },
    handleOfferMsg: function (msg) {
      let peer_id = msg['sender_id'];
      if(peer_id != this.localPeer.id) {
        console.log('offer recieved from ' + peer_id);
        this.isRemoteConnected = true;

        this.createPeerConnection(peer_id);

        let desc = new RTCSessionDescription(msg['sdp']);
        this.peer_list[peer_id].setRemoteDescription(desc)
            .then(() => {
              let local_stream = this.$refs["local_vid"].srcObject;
              local_stream.getTracks().forEach((track) => {
                this.peer_list[peer_id].addTrack(track, local_stream);
              });
            })
            .then(() => {
              return this.peer_list[peer_id].createAnswer();
            })
            .then((answer) => {
              return this.peer_list[peer_id].setLocalDescription(answer);
            })
            .then(() => {
              console.log('sending answer to <${peer_id}> ...');
              this.sendViaServer({
                "sender_id": this.localPeer.id,
                "target_id": peer_id,
                "type": "answer",
                "sdp": this.peer_list[peer_id].localDescription
              });
            })
            .catch(this.log_error);
      }
    },
    handleAnswerMsg: function (msg) {
      let peer_id = msg['sender_id'];
      if(peer_id != this.localPeer.id) {
        this.isRemoteConnected = true;
        console.log('answer recieved from ' + peer_id);
        this.isRemoteConnected = true;
        let desc = new RTCSessionDescription(msg['sdp']);
        this.peer_list[peer_id].setRemoteDescription(desc)
      }
    },
    handleNewICECandidateMsg: function (msg) {
      let peer_id = msg['sender_id'];
      if(peer_id != this.localPeer.id) {
        console.log('ICE candidate recieved from ' + peer_id);
        let candidate = new RTCIceCandidate(msg.candidate);
        this.peer_list[peer_id].addIceCandidate(candidate).catch(this.log_error);
      }
    },
    sendViaServer: function (data) {
      socket.emit("data", data);
    },
    destroyComponent: function () {
      console.log("disconnect")
      for ( let peer_id in this.peer_list ) {
        console.log(peer_id)
        console.log(this.peer_list[peer_id])
        if(this.peer_list[peer_id]) this.peer_list[peer_id].close()

      }
      this.resetRoom()
      console.log("SOCKET DISCONNECT")
      socket.disconnect()
    },
    log_error: function (e) {
      console.log("[ERROR] ", e);
    },
    addVideo: function(peer_id, peer_name){
      this.video_list[peer_id] = peer_name;
    },
    startWebRtc: function() {
      console.log("startWebRtc..........")
      for(let peer_id in this.peer_list){
        this.invite(peer_id);
      }
    },
    settings_user: function() {
      this.ctrl_remote_setting = false;
      this.ctrl_local_camera = !this.ctrl_local_camera;
    },
    play_room: function() {
      console.log("play_room")
      if(this.play_with_remote){
        this.destroyComponent()
      }
      this.play_with_remote = !this.play_with_remote;
    }
  },
  sockets: {
    connect() {
      console.log("connecting...")
      this.resetRoom()
      socket.emit("join-room", {"token": localStorage.getItem('jwt')});
    },
    disconnect(data) {
      console.log("bye "+data.sid)
      this.isRemoteConnected = false
    },
    userConnect(data) {
      console.log("user-connect ", data);
      let user_sid = data.sid
      let user_name = data.name
      console.log("sid="+user_sid)
      console.log("sid="+user_name)
      console.log(this.localPeer)
      if(this.localPeer.id && user_sid != this.localPeer.id){
        console.log("there is an overwriting")
      }else {
        console.log(user_sid + "::" + this.localPeer.id)
        this.localPeer.id = user_sid;
        this.localPeer.name = user_name;
        this.peer_list[this.localPeer.id] = undefined;
        this.addVideo(this.localPeer.id, this.localPeer.name)
      }
    },
    userList(data) {
      console.log("user list recvd ", data);
      if( "list" in data) {
        let recvd_list = data["list"];
        for(let peer_id in recvd_list) {
          this.peer_list[peer_id] = undefined;
          this.addVideo(peer_id, recvd_list[peer_id])
        }
        this.startWebRtc()
      }
    },
    data(msg) {
      console.log(msg)
      switch(msg["type"]) {
        case "offer":
          this.handleOfferMsg(msg);
          break;
        case "answer":
          this.handleAnswerMsg(msg);
          break;
        case "new-ice-candidate":
          this.handleNewICECandidateMsg(msg);
          break;
      }
    }
  }
}
</script>

<style scoped>

@font-face {
  font-family: "Crayons";
  src: local("Crayons"),  url(../assets/fonts/ColoredCrayons-7wPR.ttf) format("truetype");
}

.control_bar{
  background-color: black;
  z-index: -1;
}

#remote_setting #volume_setting span{
  padding-right: 5px;
}

#local_vid{
  width: 90%;
  margin-left: 5%;
}

#local_video {
  right: 15px;
  position: fixed;
  border-radius: 16px;
  bottom: 5px;
  background-color: whitesmoke;
  height: 360px;
  width: 350px;
  background-color: black;
  padding-top: 10px;
  z-index: 10;
}

#local_video #username{
  color: white;
  font-family: "Crayons";
  font-size: 25px;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}

.btn-play-camera span{
  padding-top: 0px !important;
  vertical-align: middle;
  margin: 0;
  font-size: 50px !important;
}

#btn-local-camera span{
  padding-top: 0px !important;
  vertical-align: middle;
  margin: 0;
  font-size: 32px !important;
}

.control_bar img{
  height: 35px;
}

#username{
  padding-left: 30px;
  padding-top: 10px;
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize;
  float: left;
  color: white;
}

#remoteVideo{
  width: 100%;
}

#waiting_box{
  margin: auto;
  width: 100%;
}

#waiting_box {
  text-align: center;
  size: 80px;
  font-family: "Crayons";
}

#waiting_box h3{
  text-align: center;
  color: white;
  margin-top: 120px;
  font-size: 120px;
}

#waiting_box #start_class{
  background-color: transparent;
  border: 0px solid transparent;
  color: dodgerblue;
  font-size: 80px;
  text-decoration: underline;
}

#waiting_box #check_class{
  background-color: transparent;
  border: 0px solid transparent;
  color: dodgerblue;
  font-size: 80px;
  text-decoration: underline;
}

#rocket button{
  background-color: transparent;
  border: none;
}

#blackboard{
  height: calc(100vh - 80px);
}

.blackboard_color_green{
  background-color: #2e6850;
}

.blackboard_color_black{
  background-color: black;
}

#ctrl_video{
  float: left;
  margin-left: 50px;
}

#ctrl_video button{
  background-color: white !important;
}

#ctrl_video button:hover{
  background-color: darkgrey !important;
}

#esci{
  width: 150px !important;
  height: 50px;
  display: block;
  float: left;
  border-radius: 12px;
  background-color: #d70a2e !important;
  margin-left: 50px;
  margin-top: 5px;
}

#esci:hover{
  background-color: darkgrey !important;
}

.d_vertical{
  object-fit: cover;
  object-position: center;
  height: 90vh;
}

.d_horizontal{
  object-fit: cover;
  object-position: center;
  height: 90vh;
}

#bttn_changevol{
  width: 100px;
  height: 30px;
  background-color: green
}

@media only screen and (max-width : 900px) {
  #username{
    display: none;
  }

  #ctrl_video{
    margin-left: 0px;
  }

  #esci{
    margin-left: 10px;
  }
}

@media only screen and (max-width : 700px) {
  #username{
    display: none;
  }

  #ctrl_video{
    margin-left: 0px;
  }

  #esci{
    margin-left: 10px;
  }

  #local_video{
    bottom: 80px;
    right: 3px;
  }
}

@media only screen and (min-width : 950px) {
  #remoteVideo{
    width: 90vh;
    display: block;
    margin: auto;
  }
}

</style>