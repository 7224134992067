import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { LayoutPlugin } from 'bootstrap-vue'

//socket definition
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';

//export const BASE_URL = "http://localhost" //dev
//export const BASE_URL = "https://www.kiro.com"
export const BASE_URL = "https://ivo2-api.elmec.services" //production
export const socket = io(BASE_URL,{autoConnect: false, secure: true});

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.config.productionTip = false
Vue.use(VueSocketIOExt, socket);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(LayoutPlugin)
Vue.use(VueAxios,axios)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
