import { render, staticRenderFns } from "./RemoteCamera.vue?vue&type=template&id=56b19273&scoped=true&"
import script from "./RemoteCamera.vue?vue&type=script&lang=js&"
export * from "./RemoteCamera.vue?vue&type=script&lang=js&"
import style0 from "./RemoteCamera.vue?vue&type=style&index=0&id=56b19273&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b19273",
  null
  
)

export default component.exports