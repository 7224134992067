<template>
  <router-view></router-view>
</template>

<style>
  @import './assets/css/app.css';
  @import 'https://fonts.googleapis.com/icon?family=Material+Icons';
</style>

<script>
export default {
  name: 'App',

  data() {
    return {
    }
  }
}
</script>


<style>

</style>
