<template>
  <b-container id="form_login" fluid="sm">
    <b-col cols="12">
      <b-form @submit.prevent="doLogin">
        <b-form-row>
          <b-col>
            <label>Username</label>
            <b-form-input v-model="username" id="username" ref="username" placeholder="Inserisci il tuo username"></b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <label>Password</label>
            <b-form-input v-model="password" id="password" ref="password" type="password" placeholder="Inserisci la tua password"></b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="text-right">
            <b-button type="submit" class="button">Accedi</b-button>
          </b-col>
        </b-form-row>
        <b-form-row v-if="error401">
          <b-col>
            <p>Username or password is incorrect</p>
          </b-col>
        </b-form-row>
      </b-form>
    </b-col>
  </b-container>
</template>

<script>
import {BASE_URL} from "@/main";

export default {
  name: 'Login',
  props: {
    title: String
  },
  data() {
    return {
      username: '',
      password: '',
      error401: false,
      errorForm: false
    }
  },
  methods: {
    doLogin() {
      if(this.username && this.password){
        this.$http.post(BASE_URL+'/api/login', {
          "username": this.username,
          "password": this.password
        }, {
          headers: {
            'Content-type': 'application/json'
          }
        })
        .then((response) => {
          localStorage.setItem('jwt', response.data.jwt)
          let r = response.data.type == 2 ? '/admin' : '/'
          this.$router.push(r)
          console.log(response)
        })
        .catch((error) => {
          this.error401 = true;
          console.log(error)
        })
      } else {
        this.errorForm = true;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.form-row {
  padding-top: 20px
}

#form_login button{
    background-color: green;
}

label {
  display: block;
  font-weight: bold;
}

</style>
