<template>
  <b-container fluid="md" id="adminbox">
    <b-row>
      <b-col cols="12" class="btnctrl">
        <b-button v-on:click="get_active_users">Refresh</b-button>
        <b-button v-on:click="logout" id="esci">Esci</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div id="list_active_users">
          <div class="user header">
            <div class="usercol">Username</div>
            <div class="usercol type">T</div>
            <div class="usercol">IP</div>
            <div class="usercol time">Date</div>
            <div class="usercol">Room</div>
            <div class="usercol">Sid</div>
          </div>
          <div v-for="user in active_users" v-bind:key="user.username" class="user">
            <div class="usercol">{{ user.username }}</div>
            <div class="usercol type">{{ user.type }}</div>
            <div class="usercol">{{ user.ip }}</div>
            <div class="usercol time">{{ user.time }}</div>
            <div class="usercol">{{ user.room_id }}</div>
            <div class="usercol">{{ user.sid }}</div>
          </div>
        </div>
      </b-col>
    </b-row>

    <nav class="navbar fixed-top navbar-light control_bar" id="header">
      <div class="navbar-brand">
        <img src="../assets/images/elmec.png"/>
      </div>
    </nav>
  </b-container>
</template>

<script>
import {BASE_URL} from "@/main";

export default {
  name: 'Admin',
  props: {
  },
  data() {
    return {
      room_id: '',
      active_users: []
    }
  },
  mounted() {
    this.get_active_users()
  },
  methods: {
    get_active_users: function(){
      this.$http.get(BASE_URL + '/api/user/active', {
        headers: {
          'Content-type': 'application/json',
          'authorization': 'Bearer ' + localStorage.getItem("jwt")
        }
      }).then((response) => {
        console.log("get active users: " + response["status"])
        this.active_users  = []
        response.data.users.forEach((p) => {
          this.active_users.push(p)
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    logout: function(){
      localStorage.removeItem('jwt')
      this.$router.push('/login')
    }
  }
}
</script>

<style>

body{
  background-color: black !important;
}

#adminbox{
  margin-top: 80px;
  color: white;
}

.user{
  border-bottom: 1px solid white;
  height: 40px;
  padding-left: 15px;
  padding-right: 5px;
  margin-top: 10px;
}

.usercol{
  float: left;
  height: 35px;
  width: 120px;
}

.type{
  width: 25px;
}

.time{
  width: 300px;
}

.header{
  font-weight: bold;
  color: green;
}

.btnctrl{
  text-align: right;
}

.btnctrl button{
  margin-left: 20px;
  width: 80px;
}

#esci{
  background-color: #d70a2e;
}

</style>