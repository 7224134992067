import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/components/Login'
import Room from '@/components/Room'
import Admin from '@/components/Admin'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Room,
        meta: {
            requiresAuth: true,
            pageTitle: {
                it: 'Home',
                en: 'Home',
            }
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            pageTitle: {
                it: 'Login',
                en: 'Login',
            }
        }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: {
            requiresAuth: true,
            pageTitle: {
                it: 'Admin',
                en: 'Admin',
            }
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        console.log('ERROR ROUTER RELOAD')
        window.location.reload()
    }
})

router.beforeEach((to, from, next) => {
    let token = (localStorage.getItem('jwt') == "null") ? null : localStorage.getItem('jwt')
    if (token == null) {
        token = (to.query.token) ? to.query.token : localStorage.getItem('jwt')
        localStorage.setItem('jwt', token)
    }
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (token == null) {
            next('/login')
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router