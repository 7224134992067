<template>
  <b-container v-if="account_loaded" fluid="fluid" id="room">
    <b-row>
      <b-col>
        <remote-camera :room_id="room_id" :username="username" :type="type"></remote-camera>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
  @import '../assets/css/app.css';
  @import 'https://fonts.googleapis.com/icon?family=Material+Icons';
</style>

<script>
import RemoteCamera from './RemoteCamera.vue'
import {BASE_URL} from "@/main";

export default {
  name: 'Room',
  components: {
    RemoteCamera
  },
  data() {
    return {
      username: '',
      room_id: '',
      type: 0,
      account_loaded: false
    }
  },
  crated(){

  },
  mounted() {
    this.getRoomId()
  },
  methods: {
    getRoomId: function(){
      this.$http.get(BASE_URL+'/api/account', {
        headers: {
          'authorization': 'Bearer '+localStorage.getItem('jwt')
        }
      })
      .then((response) => {
        this.room_id = response.data["room_id"]
        this.username = response.data["username"]
        this.type = response.data["type"]
        this.account_loaded = true
      })
      .catch((error) => {
        localStorage.removeItem('jwt')
        console.log(error)
        this.$router.push('/login')
      })
    }
  }
}
</script>


<style>

#room{
  padding: 0px;
}

#room .row{
  margin: 0px !important
}

#room .col{
  padding: 0px;
}

#login_logo{
  width: 70%
}

#login{
  margin-top: 50px;
}
</style>
