  <template>
  <b-container id="login" fluid="md">
    <b-row id="login_background">
      <b-col sm="12" lg="5">
        <div  id="form_area">
          <Login title="Login"></Login>
        </div>
        <h2>Con IVO vai a scuola</h2>
      </b-col>
      <b-col sm="12" lg="1">
      </b-col>
      <b-col sm="12" lg="6" id="box_logo">
        <img src="../assets/images/ivo2senzaScritta.svg" id="big_logo"/>
      </b-col>
    </b-row>
    <nav class="navbar fixed-top navbar-light control_bar" id="header">
      <div class="navbar-brand">
        <img src="../assets/images/elmec.png"/>
      </div>
    </nav>
  </b-container>
</template>

<style>
  @import '../assets/css/app.css';
</style>

<script>
import Login from './LoginForm.vue'

export default {
  name: 'LoginBase',
  components: {
    Login
  }
}
</script>

<style>
body:has(#login){
  background-color: green !important;
}

#login{
  padding-top: 100px;
}

#login_background{
  padding-top: 10px
}

#form_area{
  margin-top:30px;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
}

#big_logo{
  width: 250px;
}

#header{
  background-color: white;
}

h2{
  width: 100%;
  font-size: 31pt !important;
  font-family: "Lucida Sans" !important;
  text-align: center;
  padding-top:40px;
  color: white;
}

@media only screen and (max-width : 900px) {
  #big_logo{
    margin-top: 20px;
  }

  #box_logo{
    margin: auto;
    text-align: center;
  }

  h2{
    display: none;
  }
}
</style>
